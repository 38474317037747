import { template as template_2f6057b8762a4b2ea02cd7cf2125436b } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_2f6057b8762a4b2ea02cd7cf2125436b(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
