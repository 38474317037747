import { template as template_59b66e3208574439b1d89e78dcfb1a07 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_59b66e3208574439b1d89e78dcfb1a07(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
