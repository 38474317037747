import { template as template_74e1cc893e534884bde15fc94e7f7a58 } from "@ember/template-compiler";
const SidebarSectionMessage = template_74e1cc893e534884bde15fc94e7f7a58(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
