import { template as template_1a7e4b0c142d43adb4d9baaeccdca9bd } from "@ember/template-compiler";
const WelcomeHeader = template_1a7e4b0c142d43adb4d9baaeccdca9bd(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
