import { template as template_1ed45a6cf89a4d7d929e7dc253012335 } from "@ember/template-compiler";
const FKLabel = template_1ed45a6cf89a4d7d929e7dc253012335(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
