import { template as template_afe22a777e2b4bd6ac0484b6e89286b9 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuShareButton = template_afe22a777e2b4bd6ac0484b6e89286b9(`
  <DButton
    class="post-action-menu__share share"
    ...attributes
    @action={{@buttonActions.share}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.share_action"}}
    @title="post.controls.share"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuShareButton;
